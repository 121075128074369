import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "how-to-start-playing-farming-season-"
    }}>{`How to start playing Farming Season ?`}</h3>
    <p>{`To start playing, connect your wallet to the game dashboard. And you need to have at least 1000 CLC to start playing ( for buying NFT assets ).`}</p>
    <p><img alt="howtostart" src={require("./public/images/white.png")} /></p>
    <p>{`Buy characters and the farmlands that you want from the dashboard. If you don’t have any farmland and enter the game, the game will show a warning pop-up telling you to buy a farmland.`}</p>
    <p><img alt="howtostart" src={require("./public/images/howto-25.png")} /></p>
    <p><img alt="howtostart" src={require("./public/images/howto-2.png")} /></p>
    <p>{`If you already have at least one farmland, you can enter the game and select the farmland to start playing.`}</p>
    <p><img alt="howtostart" src={require("./public/images/howto-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      